<template>
    <div v-if="subColumn">
        <div v-if="item[column]">{{ item[column][subColumn] }}</div>
    </div>
    <div v-else class="px-2 py-1">{{ item[column] }}</div>
</template>

<script>
export default {
    name: "base-column",
    props: ['column', 'item', 'subColumn'],
    methods: {},
}
</script>

<style scoped>

</style>