<template>
    <div v-if="showPaginator" class="flex-1 flex items-center justify-between">
        <div>
            <p class="text-sm text-gray-700">
                Showing
                {{ ' ' }}
                <span class="font-medium">{{ $props.currentPage === 1 ? 1 : (($props.currentPage - 1) * $props.recordsPerPage) + 1 }}</span>
                {{ ' ' }}
                to
                {{ ' ' }}
                <span class="font-medium">{{ $props.currentPage * $props.recordsPerPage > $props.totalCount ? $props.totalCount : $props.currentPage * $props.recordsPerPage }}</span>
                {{ ' ' }}
                of
                {{ ' ' }}
                <span class="font-medium">{{ $props.totalCount }}</span>
                {{ ' ' }}
                results
            </p>
        </div>
        <div>
            <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <div
                    :class="['relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 transition ease-in-out duration-150',
                      showPrevious ? 'opacity-100 cursor-pointer' : 'cursor-default pointer-events-none opacity-25']"
                    @click="previous">
                    <span class="sr-only">Previous</span>
                    <i class="fal fa-chevron-left" aria-hidden="true"/>
                </div>
                <div v-for="item in pages"
                     :key="item"
                     @click="changePage(item)"
                     :class="[
                         'relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer',
                          item === $props.currentPage ? 'z-10 bg-blue-50 border-blue-500 text-blue-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                          ]">
                    {{ item }}
                </div>
                <div
                    :class="['relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 transition ease-in-out duration-150',
                      showNext ? 'opacity-100 cursor-pointer' : 'cursor-default pointer-events-none opacity-25']"
                    @click="next">
                    <span class="sr-only">Next</span>
                    <i class="fal fa-chevron-right" aria-hidden="true"/>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
import {range} from "lodash";

export default {
    name: "Paginator",

    props: {
        recordsPerPage: {
            type: Number,
            default: 10
        },
        totalCount: {
            type: Number
        },
        currentPage: {
            type: Number,
            default: 1
        }
    },

    watch: {
        currentPage(newPage) {
            this.changePage(newPage)
        }
    },

    computed: {
        totalPages() {
            return Math.ceil(this.$props.totalCount / this.$props.recordsPerPage)
        },
        pages() {
            return range(1, this.totalPages + 1)
        },
        showNext() {
            return this.totalPages > this.$props.currentPage;
        },
        showPrevious() {
            return this.$props.currentPage > 1;
        },
        showPaginator() {
            return this.$props.totalCount > this.$props.recordsPerPage
        }
    },

    methods: {
        previous() {
            if (this.$props.currentPage > 1) {
                this.changePage(this.$props.currentPage - 1)
            }

        },
        next() {
            if (this.$props.currentPage < this.totalPages) {
                this.changePage(this.$props.currentPage + 1);
            }
        },
        changePage(page) {
            this.$emit('on-change-page', page)
        }
    }
}
</script>

<style scoped>

</style>