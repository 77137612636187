<template>
    <div v-click-outside="comboOutsideClick" class="relative">
    <span class="rounded shadow-sm">
      <button type="button"
              class="flex flex-row w-full rounded border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
              id="options-menu" aria-haspopup="true" aria-expanded="true" @click="open = !open">
        <i v-if=icon :class="[icon, 'mr-2']" style="margin-top: .2rem;"></i> {{ title }}
        <svg class="-mr-1 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"/>
        </svg>
      </button>
    </span>
        <div :class="[z ? ('z-' + z) : '', 'absolute mt-2 w-56 rounded shadow-lg', open ? '' : 'hidden', right ? 'origin-top-right right-0' : '']">
            <div class="rounded bg-white shadow-xs">
                <div class="py-1">
                    <a v-for="(item, index) in items" :key="item.key + '-' + index" @click="select(item)" href="#"
                       class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">
                        {{ item.name }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
    name: "combo-button",
    props: {
        icon: String,
        title: String,
        right: Boolean,
        items: { //{name: 'Edit', key: 'edit', icon: null, counter: null, separator: false}
            type: Array,
            required: true
        },
        z: null
    },
    data: function () {
        return {
            open: false,
        }
    },
    methods: {
        comboOutsideClick: function () {
            this.open = false;
        },
        select: function (item) {
            this.open = false;
            this.$emit('select-item', item);
        }
    },
    directives: {
        ClickOutside
    }
}
</script>

<style scoped>

</style>