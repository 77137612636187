export function modalInfo() {
    return {
        visible: false,
        title: '',
        message: '',
        icon: 'info',
        action: '',
        color: 'red',
        method: function() { this.visible = false; }.bind(this),
        cancel: function() { this.visible = false; }.bind(this),
        view: function(title, message, action, icon, method, cancel) {
            this.visible = true;
            this.title = title;
            this.icon = icon;
            this.message = message;
            this.action = action;
            this.method = (method !== undefined && method !== null) ? function() {  this.visible = false; method(); }.bind(this) : function() { this.visible = false; }.bind(this);
            this.cancel = (cancel !== undefined && cancel !== null) ? function() {this.visible = false; cancel(); }.bind(this) : function() { this.visible = false }.bind(this);
        }
    }
}