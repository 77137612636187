<template>
    <business-unit-groups :companyId="$store.getters['master/company'].id"/>
</template>

<script>

import BusinessUnitGroups from "@/master/views/Home/company/BusinessUnitGroups";

export default {
    name: "BusinessUnitGroupsDashboard",
    components: {
        'business-unit-groups': BusinessUnitGroups
    }
}
</script>

<style scoped>
</style>