<template>
  <div class="fixed inset-0 overflow-y-auto" v-if="modal.visible" style="z-index: 999999">
    <div class="flex items-center justify-center min-h-screen p-4 text-center sm:block sm:p-0">
      <!--
        Background overlay, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div :class="'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-' + modal.color + '-100 sm:mx-0 sm:h-10 sm:w-10'">
              <i :class="['text-xl text-' + modal.color + '-500 far', 'fa-' + modal.icon]"></i>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                {{modal.title}}
              </h3>
              <div class="mt-2">
                <p class="text-sm leading-5 text-gray-500">
                  {{modal.message}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <span class="flex w-full rounded shadow-sm sm:ml-3 sm:w-auto">
          <button @click="modal.method()" type="button" :class="'inline-flex justify-center w-full rounded border border-transparent px-4 py-2 bg-' + modal.color + '-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-' + modal.color + '-500 focus:outline-none focus:border-' + modal.color + '-700 focus:shadow-outline-' + modal.color + ' transition ease-in-out duration-150 sm:text-sm sm:leading-5'">
            {{modal.action}}
          </button>
        </span>
          <span class="mt-3 flex w-full rounded shadow-sm sm:mt-0 sm:w-auto">
          <button @click="modal.cancel()" type="button" class="inline-flex justify-center w-full rounded border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
            Cancel
          </button>
        </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal",
  props: {
    modal: Object,
  },
  data: function() {
    return {
      open: false,
    }
  },
  methods: {
    comboOutsideClick: function() {
      this.open = false;
    }
  },
}
</script>

<style scoped>

</style>